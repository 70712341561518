export const VISIT_TYPE = {
  'TZBS': 1, // 体质辨识（弃用）
  'ZYLC': 2, // 中医临床
  'LJBZ': 3, // 六经辨证（弃用）
  'XGZT': 4, // 新冠专题（弃用）
  'XLJBZ': 5, // 中医AI - 新六经辨证
  'TZBS_CMA': 6, // 体质辨识 - 完整版
  'ZYKY': 7, // 中医康养
  'ZYAI': 8, // 中医AI - 简单中医
  'ZYZJ': 9, // 中医针灸
  'TZBS_WQYS': 10, // 体质辨识 - 王琦院士版
  'ZXYJH': 11, // 中西医结合
  'MXJB_FBQ': 12, // 慢性疾病-发作期
  'MXJB_TZBS_DT': 13, // 慢性疾病 - 体质辨识完整版
  'MXJB_TZBS_ZX': 14, // 慢性疾病 - 体质辨识直选模式
  'MXJB_TZBS_WQYS': 15, // 慢性疾病 - 体质辨识王琦院士版
  'TZBS_ZX': 16, // 体质辨识 - 直选模式
  'ZYTF': 17, // 中医贴敷
  'LG_2024': 19, // 流感2025
};