import CryptoJs from 'crypto-js';
import html2canvas from 'html2canvas';
import tbMedicine from './tb_medicine'
import { VISIT_TYPE } from './visitType';

// 密码加密
export function getEnc(password) {
  const str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let randomStr = '';
  for (let i = 10; i > 0; --i) {
    randomStr += str[Math.floor(Math.random() * str.length)];
  }
  const key = CryptoJs.enc.Utf8.parse('BEYOND' + randomStr);
  const iv = CryptoJs.enc.Utf8.parse('!BEYOND@SIGNAL#~');
  const pwd = CryptoJs.AES.encrypt(password, key, { iv: iv, mode: CryptoJs.mode.CBC, padding: CryptoJs.pad.Pkcs7 }).toString();
  let keyStr = '';
  for (let i = 0; i < 10; i++) {
    keyStr += (pwd.substring(0, 10).split('')[i] + randomStr.split('')[i]);
  }
  return keyStr + pwd.substring(10, pwd.length);
}
// 解密
export function getDec(data) {
  const pwdStr = data.substring(0, 20);
  let str = '';
  let oddStr = '';
  for (let i = 0; i < 20; i++) {
    if (!(i === 0 || i % 2 === 0)) {
      str += pwdStr[i];
    } else {
      oddStr += pwdStr[i];
    }
  }
  const encryptedHexStr = CryptoJs.enc.Hex.parse(atob(oddStr + data.substring(20)));
  const key = CryptoJs.enc.Utf8.parse('BEYOND' + str);
  const iv = CryptoJs.enc.Utf8.parse('!BEYOND@SIGNAL#~');
  const pwd = CryptoJs.AES.decrypt(CryptoJs.enc.Base64.stringify(encryptedHexStr), key, { iv: iv, mode: CryptoJs.mode.CBC, padding: CryptoJs.pad.Pkcs7 }).toString();
  const newPwd = pwd.toString(CryptoJs.enc.Utf8);
}
// 是否全屏
function isFullScreen() {
  return !!(
    document.fullscreen ||
    document.mozFullScreen ||
    document.webkitIsFullScreen ||
    document.webkitFullScreen ||
    document.msFullScreen
  );
}
// 全屏 退出全屏
export function fullScreen(ele) {
  const isFull = isFullScreen();
  if (isFull) {
    if (document.exitFullScreen) {
      document.exitFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  } else {
    if (ele.requestFullscreen) {
      ele.requestFullscreen();
    } else if (ele.mozRequestFullScreen) {
      ele.mozRequestFullScreen();
    } else if (ele.webkitRequestFullscreen) {
      ele.webkitRequestFullscreen();
    } else if (ele.msRequestFullscreen) {
      ele.msRequestFullscreen();
    }
  }
}

// 打印机打印
export function handlePrint(id, callback) {
  // 先用html2canvas将页面整个转为一张截图，再打印，防止出现echarts无法打印
  const dom = document.getElementById(id);
  html2canvas(dom, {
    scale: 2,
    width: dom.offsetWidth,
    height: dom.offsetHeight,
  }).then((canvas) => {
    const context = canvas.getContext('2d');
    context.mozImageSmoothingEnabled = false;
    context.webkitImageSmoothingEnabled = false;
    context.msImageSmoothingEnabled = false;
    context.imageSmoothingEnabled = false;
    const src64 = canvas.toDataURL();
    const contentWidth = canvas.width;
    const contentHeight = canvas.height;
    const imgWidth = 800; // 根据纸张宽度设定
    const imgHeight = (800 / contentWidth) * contentHeight;
    const img = new Image();
    const div = document.createElement('div');
    div.appendChild(img);
    img.setAttribute('src', src64);
    img.setAttribute('width', imgWidth);
    img.setAttribute('height', imgHeight);
    img.setAttribute('id', 'imgs');
    div.setAttribute('id', 'printImg');
    document.body.appendChild(div);
    const printContent = (window).document.getElementById('printImg').innerHTML;
    img.onload = () => {
      const printWindow = window.open('', '_blank',
        'width=1200,height=900,location=0,menubar=0,status=0,toolbar=0,scrollbars=1');
      printWindow.document.write(printContent);//这里向新建的窗体中写入BODY的内容，注意，外边加的额外DIV是有必要的，它里面CSS可以控制打印时不会出现空白页
      const style = document.createElement('style');
      style.innerHTML = '@page {margin: 0 auto} body{margin: 0 auto;}'; // 移除页眉页脚
      printWindow.document.head.appendChild(style);
      printWindow.focus();//当前新建的窗口获得焦点
      printWindow.document.close();//关闭新建窗口的文档输出流，这个是必须的，否则下面的打印语句无效
      printWindow.print();//打印当前新建窗口中的内容
      printWindow.close();//关闭新建的窗口
    };
    document.body.removeChild(document.getElementById('printImg'));
    callback && callback();
  });
}

export const getGlobalToken = () => {
  if (localStorage.loginInfo) {
    const { token, userId } = JSON.parse(localStorage.loginInfo);
    const tokenJson = JSON.stringify({ token: token, userId: userId, routeId: localStorage.currentRoute || 1, source: 1, });
    return getEnc(tokenJson);
  }
  return '';
};

// 校验药方格式是否正确
export const handleRegExp = (v) => {
  // const reg = /^(([\u4e00-\u9fa5]+)((\([\u4e00-\u9fa5]+\))?|(（[\u4e00-\u9fa5]+）)?))(0|[1-9][0-9]*)g$/;
  // const reg = /^(((\([\u4e00-\u9fa5]+\))?|(（[\u4e00-\u9fa5]+）)?)([\u4e00-\u9fa5]+)((\([\u4e00-\u9fa5]+\))?|(（[\u4e00-\u9fa5]+）)?))(0|([0-9]*|\d*.\d{1}?\d*)?)g$/;
  const reg = /^(\uff08.+\uff09)?[\u4e00-\u9fa5]+(\uff08.+\uff09)?\d+(\.\d+)?g$/;
  // const reg = /^(((\([\u4e00-\u9fa5]+\))?|(（[\u4e00-\u9fa5]+）)?)([\u4e00-\u9fa5]+)((\([\u4e00-\u9fa5]+\))?|(（[\u4e00-\u9fa5]+）)?)(0|[1-9][0-9]*))g$/;
  return reg.test(v);
};

// 获取药方名称
export const handleNameRegExp = (str) => {
  // const reg = /(（?\(?[\u4e00-\u9fa5]+）?\)?)?[\u4e00-\u9fa5]+(（?\(?[\u4e00-\u9fa5]+）?\)?)?/g;
  const reg = /(\uff08.+\uff09)?[\u4e00-\u9fa5]+(\uff08.+\uff09)?/g;
  const names = str.match(reg) || [];
  return names.join('');
};

// 获取药方克重
export const handleNumRegExp = (str) => {
  // const reg = /(0|[1-9][0-9]*)/g;
  // const reg = /\d+\.?\d+/g;
  const reg = /(0|([1-9]\d*))(\.\d+)?/g;
  const nums = str.match(reg) || [];
  return Number(nums.join(''));
};

// base64图片格式转文件流
export const base64toFile = (data, fileName, imageType) => {
  const dataArr = data.split(",");
  const byteString = atob(dataArr[1]);
  const options = {
    type: imageType,
    endings: "native"
  };
  const u8Arr = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    u8Arr[i] = byteString.charCodeAt(i);
  }
  return new File([u8Arr], fileName + ".jpg", options);//返回文件流
};

export const filterTbMedicineData = () => {
  const resultMap = {};
  (tbMedicine || []).forEach(item => {
    resultMap[item.medicine_name] = item
  })
  return resultMap
}

// 身份证正则验证规则
export const regexIdcard = (val) => /^[1-9]\d{5}(18|19|20|21|22)?\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|[Xx])$/.test(val);

// 手机号正则验证规则
export const regexPhone = (val) => /^1[3-9]\d{9}$/.test(val);

// 姓名正则验证规则
export const regexName = (val) => /^[A-Za-z\u4e00-\u9fa5]+$/.test(val);

// 正整数正则验证规则
export const regexInteger = (val) => /^[1-9]\d*$/.test(val);

// 身份证获取年龄
export const getAgeFromIdCard = (idCard) => {
  if (!idCard || idCard.length !== 18) {
    throw new Error('Invalid ID card number');
  }
 
  const today = new Date();
  const birthDateString = idCard.substring(6, 14);
  const birthDate = new Date(
    Number(birthDateString.substring(0, 4)),
    Number(birthDateString.substring(4, 6)) - 1, // JS中月份是从0开始的
    Number(birthDateString.substring(6, 8))
  );
 
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
 
  return age;
}

// 判断是否是体质辨识
export const isPhysique = (visitType) => {
  if (!visitType) return false;
  if (
    visitType == VISIT_TYPE.TZBS_CMA ||
    visitType == VISIT_TYPE.TZBS_WQYS ||
    visitType == VISIT_TYPE.TZBS_ZX ||
    visitType == VISIT_TYPE.MXJB_TZBS_DT ||
    visitType == VISIT_TYPE.MXJB_TZBS_WQYS ||
    visitType == VISIT_TYPE.MXJB_TZBS_ZX
  ) {
    return true;
  } else {
    return false;
  }
}

// 通过路由判断visitType
export const filterVisitypeByBouter = (path) => {
  const pathToVisitType = {
    '/clinical': VISIT_TYPE.ZYLC,
    '/clinical2': VISIT_TYPE.ZXYJH,
    '/slow': VISIT_TYPE.MXJB_FBQ,
    '/healthy': VISIT_TYPE.ZYKY,
    '/acupuncture': VISIT_TYPE.ZYZJ,
    '/application': VISIT_TYPE.ZYTF,
    '/liugan': VISIT_TYPE.LG_2024
  }
  return pathToVisitType?.[path] ?? null;
}

// 通过visitType判断路由
export const filterRouterByVisitype = (visitype) => {
  const visitTypeToPath = {
    [VISIT_TYPE.ZYLC]: '/clinical',
    [VISIT_TYPE.ZXYJH]: '/clinical2',
    [VISIT_TYPE.MXJB_FBQ]: '/slow',
    [VISIT_TYPE.ZYKY]: '/healthy',
    [VISIT_TYPE.ZYZJ]: '/acupuncture',
    [VISIT_TYPE.ZYTF]: '/application',
    [VISIT_TYPE.LG_2024]: '/liugan'
  }
  return visitTypeToPath?.[visitype] ?? null;
}

export function debounce(func, delay) {
  let timer = null;
  return function(...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
