// 客户类型
export const CUSTOMER_TYPEC_CODE = {
  0: '诊所',
  1: '医院',
  2: '机构',
};

// 处方类型
export const PRESCTIPTION_TYPE_MAP = {
  100: '推荐处方',
  200: '膏方',
  300: '足浴方',
  400: '香囊方',
  500: '药酒方',
  600: '茶饮方',
  700: '药膳方',
  800: '针灸方',
  900: '贴敷方'
};