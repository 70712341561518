import { CUSTOMER_TYPEC_CODE } from "@/utils/constant";

export default {
  namespaced: true,
  state: () => ({
    userInfo: {},
    isLogin: false,
    isNonMedical: false, // 是否是非医端
    authoritys: {}, // 用户权限
    customerTyeCodeName: CUSTOMER_TYPEC_CODE[0]
  }),
  getters: {
    getUserInfo: (state) => {
      return state.userInfo;
    }
  },
  mutations: {
    setUserInfo(state, val) {
      state.userInfo = val;
    },
    setName( state, val) {
      state.userInfo.userName = val
    },
    setIsLogin(state, val) {
      state.isLogin = val;
    },
    setIsNonMedical(state, val) {
      state.isNonMedical = val;
    },
    setAuthoritys(state, val) {
      state.authoritys = val;
    },
    setCustomerTyeCodeName(state, val) {
      state.customerTyeCodeName = CUSTOMER_TYPEC_CODE[val];
    }
  },
};
